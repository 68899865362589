import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineEye,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist, removeFromWishlist } from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import styles from "../../../styles/styles";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="w-full rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white">
        {/* Product Image */}
        <div className="relative">
          <Link to={`${isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
            <img
              src={`${data.images && data.images[0]?.url}`}
              alt={data.name}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
          </Link>
          {/* Wishlist and Quick View Icons */}
          <div className="absolute top-2 right-2 flex space-x-2">
            {click ? (
              <AiFillHeart
                size={24}
                className="text-red-600 hover:text-red-800 cursor-pointer"
                onClick={() => removeFromWishlistHandler(data)}
                title="Remove from wishlist"
              />
            ) : (
              <AiOutlineHeart
                size={24}
                className="text-gray-600 hover:text-red-600 cursor-pointer"
                onClick={() => addToWishlistHandler(data)}
                title="Add to wishlist"
              />
            )}
            <AiOutlineEye
              size={24}
              className="text-gray-600 hover:text-gray-900 cursor-pointer"
              onClick={() => setOpen(!open)}
              title="Quick view"
            />
          </div>
        </div>

        {/* Product Info */}
        <div className="p-4">
          {/* Shop Name */}
          <Link to={`/shop/preview/${data?.shop._id}`}>
            <h5 className="text-xs text-gray-400 uppercase">{data.shop.name}</h5>
          </Link>

          {/* Product Name */}
          <Link to={`${isEvent ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
            <h4 className="text-lg font-semibold text-gray-900 truncate">
              {data.name.length > 30 ? data.name.slice(0, 30) + "..." : data.name}
            </h4>
          </Link>

          {/* Product Price */}
          <div className="flex items-center justify-between pt-3">
            <span className="text-xl font-bold text-gray-800">
              {data.discountPrice} KES {/* Discounted Price */}
            </span>
            {data.originalPrice && (
              <span className="text-sm text-gray-500 line-through">
                {data.originalPrice}$
              </span>
            )}
          </div>

          {/* Add to Cart Button */}
          <div className="mt-4">
            <button
              className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
              onClick={() => addToCartHandler(data._id)}
            >
              Add to Cart
            </button>
          </div>
        </div>

        {/* Quick View Modal */}
        {open && <ProductDetailsCard setOpen={setOpen} data={data} />}
      </div>
    </>
  );
};

export default ProductCard;
