import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";

const Hero = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // if(term.length > 0) {
    //   const filteredProducts =
    //   allProducts &&
    //   allProducts.filter((product) =>
    //     product.name.toLowerCase().includes(term.toLowerCase())
    //   );
    // setSearchData(filteredProducts);
    // } else {
    //   setSearchTerm([])
    // }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filteredProducts =
        allProducts &&
        allProducts.filter((product) =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setSearchData(filteredProducts);
    } else {
      setSearchData([]); // Reset searchData when searchTerm is empty
    }
  }, [searchTerm, allProducts]); 

  return (
    <div
      className={`relative min-h-[40vh] 800px:min-h-[50vh] w-full bg-blue-500 bg-no-repeat ${styles.noramlFlex}`}
      // style={{
      //   backgroundImage:
      //     "url(https://themes.rslahmed.dev/rafcart/assets/images/banner-2.jpg)",
      // }}
    >
      <div className={`${styles.section} w-[90%] flex items-center justify-center 800px:w-[60%]`}>
        <div className="w-[80%] relative">
            <input
              type="text"
              placeholder="Search Product..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-[40px] w-full px-2 border-[#00129A] border-[2px] rounded-md"
            />
            <AiOutlineSearch
              size={30}
              className="absolute right-2 top-1.5 cursor-pointer"
            />
            {searchData && searchData.length > 0  ? (
              <div className="absolute min-h-[30vh] bg-slate-50 shadow-sm-2 z-[9] p-4">
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`}>
                        <div className="w-full flex items-start-py-3">
                          <img
                            src={`${i.images[0]?.url}`}
                            alt=""
                            className="w-[40px] h-[40px] mr-[10px]"
                          />
                          <h1>{i.name}</h1>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
          </div>
        
        {/* <Link to="/products" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Shop Now
                 </span>
            </div>
        </Link> */}
      </div>
    </div>
  );
};

export default Hero;
